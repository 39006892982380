import React, { useState } from "react";
import styled from "styled-components";
import LayoutContainer from "./Container";
import { MD_BREAKPOINT } from "../../breakpoints";
import logo from "../../assets/images/Logo_Business.png";
import Button from "../Button";
import { Link, NavLink } from "react-router-dom";
import { CongressDropdown } from "./CongressDropdown";

export default function Header({ showButton }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Container>
      <LayoutContainer>
        <Content>
          <NavContainer>
            <Link style={{ color: "#1C5EA4" }} to="/">
              <Logo src={logo} />
            </Link>
            <HamburgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <span></span>
              <span></span>
              <span></span>
            </HamburgerButton>
          </NavContainer>
          <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <Navigation isOpen={isMenuOpen}>
              <CongressDropdown />
              <StyledNavLink to="/about-us">Nosotros</StyledNavLink>
              <StyledNavLink to="/images">Fotos</StyledNavLink>
              <StyledNavLink to="/faq">Preguntas</StyledNavLink>
              <StyledNavLink to="/contact">Contacto</StyledNavLink>
            </Navigation>
            <Button small to="/payment/method" rounded color="#2AB155">
              Pagar Congreso
            </Button>
          </div>
        </Content>
      </LayoutContainer>
    </Container>
  );
}

const NavContainer = styled.div`
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
`;

const Logo = styled.img`
  height: 6rem;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1rem;
    padding-top: 2rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const HamburgerButton = styled.button`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 2rem;
  height: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  span {
    width: 2.3rem;
    height: 0.3rem;
    background: #1c5ea4;
    border-radius: 10px;
    transition: all 0.3s linear;
  }

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    display: flex;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: #000000;
  text-decoration: none;
  flex-shrink: 0;

  &.active {
    font-weight: bold;
  }
`;
