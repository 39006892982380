import styled, { keyframes } from "styled-components";
import Layout from "../../components/Layout";
import React, { useState } from "react";
import { childVariants } from "../../framerOptions";
import { LG_BREAKPOINT, MD_BREAKPOINT } from "../../breakpoints";
import { useRequest } from "../../fetchboi/hooks";

export default function ImagesSection() {
  const [images, loading] = useRequest({
    url: "/congresses/images",
  });

  return (
    <Layout>
      <Layout.Title>Fotos</Layout.Title>
      <Container variants={childVariants}>
        <ImagesContainer>
          {loading && (
            <>
              <ImageSkeleton />
              <ImageSkeleton />
              <ImageSkeleton />
              <ImageSkeleton />
            </>
          )}
          {images?.length > 0 &&
            images?.map((image) => (
              <ImageItem key={image.UUID} image={image} />
            ))}
        </ImagesContainer>
      </Container>
    </Layout>
  );
}

const ImageItem = ({ image }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <StyledImage
        src={image.PhotoURL}
        alt={image.Name}
        onClick={() => setIsModalOpen(true)}
        style={{ cursor: "pointer" }}
      />
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <ModalImage src={image.PhotoURL} alt={image.Name} />
        </Modal>
      )}
    </>
  );
};

const StyledImage = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
  max-height: 350px;
  border-radius: 12px;
`;
const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  padding-inline: 24px;

  @media (max-width: ${LG_BREAKPOINT}em) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${MD_BREAKPOINT}em) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Container = styled.div`
  background: #f2f5f8;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 0;
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const Skeleton = styled.div`
  display: inline-block;
  height: ${(props) => props.height || "350px"};
  width: ${(props) => props.width || "100%"};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 12px;
  margin-top: ${(props) => props.marginTop || "0"};
`;

const ImageSkeleton = styled(Skeleton)`
  margin-bottom: 16px;
  grid-column: span 1;
  margin: auto;
  height: 350px;
  max-width: 400px;
  display: block;
`;

const Modal = ({ children, onClose }) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -48px;
  right: -10px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }
`;
